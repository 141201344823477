import React from 'react';
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import LinkIcon from '@mui/icons-material/Link';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Slider from '@mui/material/Slider';
import Button from '@mui/material/Button';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
export const SkinPanel = (item) => {
  const float_slider_val = Number(parseFloat(item.item.result.floatvalue)*100);

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }
  function removeItem() {
    item.onRemove(item.item.inspect_url);
  }
  const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  });

  const marks = [
    {
      value: 0,
      label: 'FN',
    },
    {
      value: 7,
      label: 'MW',
    },
    {
      value: 15,
      label: 'FT',
    },
    {
      value: 38,
      label: 'WW',
    },
    {
    value: 45,
    label: 'BS',
    },
    {
      value: 100,
      label: '1.0',
      }
];

  return (
    <Paper
    sx={{
      p: 2,
      margin: 'auto',
      maxWidth: "100%",
      flexGrow: 1,
      m: 2,
      backgroundColor: "#2E3B55",
    }}
    >
      <Grid container sx={{margin: 'auto'}} spacing={2}   alignItems="center"
  justifyContent="center">
        <Grid item>
          <ButtonBase sx={{ width: 178, height: 148 }} >
            <Img alt="CS:GO Skin" src={item.item.result.imageurl} onError={removeItem} />
          </ButtonBase>
        </Grid>
        <Grid item xs={12} sm container>
          <Grid item xs container direction="column" spacing={2}>
            <Grid item xs>
              <Box sx={{ width: 3/4 }}>
            <Slider disabled 
                sx={{
                  '& .MuiSlider-thumb': {
                      color: "yellow"
                  },
                  '& .MuiSlider-track': {
                      color: "yellow"
                  },
                  '& .MuiSlider-valueLabel': {
                    fontSize: 12,
                    fontWeight: 'normal',
                    top: -6,
                    backgroundColor: 'unset',

                  },
                  '& .MuiSlider-markLabel': {
                      color: "gray",
                      fontSize: 10
                  }
              }}
                                size="small"
                                aria-label="Always visible"
                                value={float_slider_val}
                                step={10}
                                marks={marks}
                                valueLabelDisplay="off"
                                
                        />
                        <Box>

                        
                    <Stack direction="row"   justifyContent="center"
                      alignItems="center" gap={1}>
                    <Typography sx={{fontWeight: 'bold'}} color="#ebebeb" variant="h6" >
                    {item.item.result.floatvalue}
                    </Typography>
                    <Tooltip title="Copy">
                    <IconButton aria-label="copy float value"  onClick={() => navigator.clipboard.writeText(item.item.result.floatvalue)}>
                    <ContentCopyIcon style={{ color: 'white' }}/>
                    </IconButton>
                    </Tooltip>
                    
                    </Stack>
                    </Box>

              
              </Box>
              <Typography sx={{fontWeight: 'bold'}} color="#ebebeb" gutterBottom variant="subtitle1" component="div">
                {item.item.result.full_item_name}
              </Typography>
              {
                item.item.result.paintseed ? 
                <Typography color="#ebebeb" variant="body2" >
                Pattern: {item.item.result.paintseed}
              </Typography>
              :
              null
              }
              {
                item.item.result.rarity_name ? 
                <Typography color="#ebebeb" variant="body2" >
                Rarity: {item.item.result.rarity_name}
              </Typography>
              :
              null
              }
              {
                item.item.result.origin_names ? 
                <Typography color="#ebebeb" variant="body2" >
                Origin: {item.item.result.origin_names}
              </Typography>
              :
              null
              }

            </Grid>
              <Grid item>
                <Stack direction="row" spacing={1}>
                  <Tooltip title="Copy inspect link">
                  <IconButton aria-label="copy inspect link" onClick={() => navigator.clipboard.writeText(item.item.inspect_url)}>
                    <LinkIcon />
                  </IconButton>
                  </Tooltip>
                  <Tooltip title="Inspect in-game">
                  <IconButton aria-label="inspect" color="primary" onClick={() => openInNewTab(item.item.inspect_url)}>
                    <SearchIcon />
                  </IconButton>
                  </Tooltip>
                  <Tooltip title="Trade">
                  <IconButton color="primary" aria-label="trade on cs.trade" onClick={() => openInNewTab("https://cs.trade/?market_name="+item.item.result.full_item_name)}>
                    <AddShoppingCartIcon />
                  </IconButton>
                  </Tooltip>
                  <Tooltip title="Remove">
                  <IconButton color="secondary" aria-label="remove" onClick={removeItem}>
                    <DeleteIcon />
                  </IconButton>
                  </Tooltip>
                </Stack>
              </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

