import React from 'react';
import Link from '@mui/material/Link';

import AppBar from "@mui/material/AppBar";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import Logo from "../images/cs_trade_logo_b.png";
import Stack from '@mui/material/Stack';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
export const NavigationBar = () => {


  return (
    <AppBar position="static" sx={{ background: '#2E3B55' }} >
        <Toolbar>
            <Link href="https://cs.trade">
            <Box
                component="img"
                sx={{
                height: 64,
                cursor:"pointer"
                }}
                alt="CS.TRADE"
                src={Logo}

            />
            </Link>

            <Box flexGrow={1} />
            <Stack direction="row" spacing={2}>
            <Link href="https://screenshot.cs.trade"  underline="none">
            <Button variant="outlined" startIcon={<AddAPhotoIcon/>}>
             CS:GO CS2 Screenshot Tool
            </Button>
            </Link>
            <Link href="https://cs.trade/#trader"  underline="none">
            <Button variant="outlined"  startIcon={<ChangeCircleIcon/>}>
              TRADE CS:GO & CS 2 SKINS
            </Button>
            </Link>
            </Stack>
        </Toolbar>
    </AppBar>
  );
};

