import './App.css';


import React, { useEffect, useState  } from 'react';
import { NavigationBar } from './components/NavigationBar';
import { Footer } from './components/Footer';
import { SkinPanel } from './components/SkinPanel';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import Skeleton from '@mui/material/Skeleton';
import UserService from "./services/user.service"
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import Paper from "@mui/material/Paper";
import Alert from '@mui/material/Alert';
import FAQComponent from './components/FAQComponent'; // Import the FAQComponent
import Cookies from 'universal-cookie';

const current = new Date();
const nextYear = new Date();

nextYear.setFullYear(current.getFullYear() + 1);


const App = () => {


  const cookies = new Cookies();

  const [itemArray, setItemArray] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [inspectInputValue, setInspectInputValue] = useState("");
  const onTextChange = (e) => setInspectInputValue(e.target.value);

  useEffect(() => {
    if(cookies.get('itemArray')) {
      setItemArray(cookies.get('itemArray'));
    }
  }, []);

  useEffect(() => {
    cookies.set('itemArray', itemArray, {path: '/', expires: nextYear});
  }, [itemArray])

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  function handleRemoveItem(inspect_url) {
    setItemArray(itemArray.filter(item => item.inspect_url !== inspect_url));
    
  }
  function isItemInArray(inspect_url) {
    for(var index in itemArray) {
      if(itemArray[index].inspect_url === inspect_url) {
        return true;
      }
    }
    return false;
  }



  function generateFloat(inspect_url) {
    if(inspect_url && inspect_url.startsWith("steam://rungame/730")) {
      if(!isItemInArray(inspect_url)) {
      setIsLoading(true);
      UserService.getFloat(inspect_url).then(
        (response) => {
          if(response.data.success === 0) {
            setAlertMessage(response.data.message);
            setAlertOpen(true);
          } else if(response.data.success === 1) {
            response.data.inspect_url = inspect_url;
            if(itemArray.length == 3) {
              var arr_copy = itemArray;
              arr_copy.shift();
              setItemArray([...arr_copy, response.data]);
            } else {
              setItemArray(oldArray => [...oldArray, response.data]);
            }
            
            

          }
          setIsLoading(false);

        },
        (error) => {
          setAlertMessage("There was an error getting float information.");
          setAlertOpen(true);
          setIsLoading(false);
        }
    );
    } else {
      setAlertMessage("Skin float is already on your list.");
      setAlertOpen(true);
    }
  } else {
    setAlertMessage("Please insert correct Inspect URL");
    setAlertOpen(true);
  }
}

  return (
    <React.Fragment>
      <Container sx={{backgroundColor: "#100b2d"}}>
        <Box sx={{minHeight: "calc(100vh - 40px)"}}>
        <Grid container rowSpacing={3} direction="column"  >
          <Grid item zeroMinWidth>
            <NavigationBar/>
          </Grid>
          <Box sx={{marginTop: '2px', borderRadius: 0, background: '#2E3B55'}} >
          <Box sx={{margin: "0px"}}>
          <Grid item zeroMinWidth sx={{ padding: 2}}>
            <Typography variant="h1" color="#ebebeb" sx={{       flexGrow: 0, fontSize: 50,textAlign: "center", padding: 0 }}>
              CS2 Skins Float Checker
            </Typography>
          </Grid>
          <Grid item zeroMinWidth sx={{ padding: 2}}>
            <Typography paragraph={true} color="#ebebeb" sx={{       flexGrow: 0, textAlign: "center", padding: 0}}>
            Welcome to Skins Float Checker, tool for checking the float values of your CS2 skins.  It allows you to quickly check the float value of any skin in your inventory, so you can make the right decisions when trading or selling.
            </Typography>
            <Typography paragraph={true} color="#ebebeb" sx={{       flexGrow: 0, textAlign: "center", padding: 0}}>
            To get started, simply paste skin inspect link in the form below. Our service will look up this item and provide you with detailed information about its float value, pattern number and rarity. 
            </Typography>
            <Typography paragraph={true} color="#ebebeb" sx={{       flexGrow: 0, textAlign: "center", padding: 0}}>
            Start checking your skins today and take your CS 2 and CS:GO trading to the next level!
            </Typography>
          </Grid>
          </Box>
          <Grid item zeroMinWidth sx={{ padding:2, margin:"4px"}}>
            <Grid container spacing = {1} sx={{justifyContent: 'center'}} >
              <Grid item xs={9} zeroMinWidth >
              <TextField  variant="outlined" sx={{
               input: { color: 'ebebeb' }, label: { color: '#ebebeb' } }} placeholder="steam://rungame/730/76561202255233023/+csgo_econ_action_preview" fullWidth id="filled-basic" label="Inspect URL" 
                onChange={onTextChange} 
                value={inspectInputValue}

              />
              </Grid>
              <Grid item  zeroMinWidth >
                <LoadingButton sx={{minWidth: 0, height: '100%'}} onClick={() => generateFloat(inspectInputValue)} endIcon={<SendIcon />} loading={isLoading} loadingPosition="end" variant="contained">
                  CHECK FLOAT
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
          </Box>
        
          <Box sx={{flexGrow: 1}}>
          { isLoading && (
                <Paper
                sx={{
                  p: 2,
                  margin: 'auto',
                  maxWidth: "100%",
                  flexGrow: 1,
                  m: 2,
                  backgroundColor: "#2E3B55",
                }}
                >
                <Grid container sx={{margin: 'auto'}} spacing={2}   alignItems="center"
            justifyContent="center">
                  <Grid item>
                  <Skeleton variant="rectangular" sx={{
              maxWidth: "97%",
              width: 185, height: 142,
              flexGrow: 0,
              bgcolor: '#D4C8DE',
              padding: 0
            }} />
                  </Grid>
                  <Grid item xs={12} sm container>
                    <Grid item xs container direction="column" spacing={2}>
                      <Grid item xs sx={{ width: "40%" }}>
                        <Skeleton sx={{bgcolor: '#D4C8DE'}}/>
                      </Grid>
                      <Grid item xs sx={{ width: "30%" }}>
                        <Skeleton sx={{bgcolor: '#D4C8DE'}}/>
                      </Grid>
                      <Grid item xs sx={{ width: "15%" }}>
                        <Skeleton sx={{bgcolor: '#D4C8DE'}}/>
                      </Grid>
                    </Grid>
                </Grid>
              </Grid>

            </Paper> )
          }
          {
              itemArray.slice(0).reverse().map((item, index) => (
                  <SkinPanel key={index} item={item} onRemove={handleRemoveItem} />
              ))
          }
          <FAQComponent />
          </Box>
      </Grid>

      </Box>
      <Footer/>
      </Container>
      
      <Snackbar
        anchorOrigin={{ vertical:'top', horizontal:'center' }}
        open={alertOpen}
        onClose={handleAlertClose}
      >
        <Alert variant="filled" severity="error">{alertMessage}</Alert>
      </Snackbar>
    </React.Fragment>
  );
}

export default App;