import axios from "axios";

const API_URL = "https://api-float.cs.trade/";

const getFloat = (inspect_url) => { 
  return axios.get(API_URL + "getFloat?url="+inspect_url);
};


export default {
    getFloat
};